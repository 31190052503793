.projects {
    padding: 3rem 0rem 1.5rem 0rem;
    .project-list {

        .project-item {
            padding: 1rem 1rem;
            margin-bottom: 2rem;


            .img-container {
                border-radius: 5px;
                padding: 1rem;
                background:#285172;

                .project-img {
                    position: relative;
                    width: 100%;
                    margin-bottom: -30px;
                    border-radius: 3px;
                    box-shadow: 0 1px 5px $background, 0 2px 20px $background;

                    &:hover {
                        @include easeOut();
                    }
                }
            }

            .details {
                .project-title {
                    margin: 2rem 0rem 0.45rem 0rem;
                    font-size: 1.5rem;
                    font-weight: 400;
                    color: $secondary-color;
                }

                .description {
                    font-size: 1.05rem;
                    margin-bottom: 0.75rem;
                    color: #b9d1f2;
                }

                .tech {
                    list-style: none;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    margin: 0;
                    padding: 0;

                    .tech-tag {
                        border: 1px solid $secondary-color;
                        border-radius: 5px;
                        padding: 0.12rem;
                        font-size: 0.85rem;
                        margin: 0.25rem 0.2rem 0rem 0.2rem;
                    }
                }

                .project-controls {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-items: baseline;
                    // grid-area: details;

                    a {
                        color: $primary-color;
                        text-decoration: none;
                    }

                    .preview-link,
                    .github-link {
                        padding: 5px;
                        top: 0;
                        color: $secondary-color;
                        margin: 0.7rem 0.5rem 0rem 0.25rem;
                        font-size: 0.9rem;
                        font-weight: 400;
                        border-radius: 5px;
                        letter-spacing: 0.05px;

                        &:hover {
                            cursor: pointer;
                            background: $gradient;
                            color: #ffffff;

                            a {
                                color: #ffffff;
                                opacity: 1;
                            }

                            @include easeOut();
                        }
                    }

                    .preview-link {
                        margin-left: 0.1rem;
                    }
                }
            }

            &.card {
                border-radius: 10px;
            }

        }

    }
}