@import 'config';
@import 'home';
@import 'projects';
@import 'articles';


* {
    box-sizing: border-box;
}

html, body{
    height: 100%;
    margin: 0;
    padding: 0;
}

// Body Styling
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-family: 'Nunito', sans-serif;
    font-family: "Rubik","Arial",sans-serif;
    font-size: 1.1rem;
    background: $background;
    color: #ffffff;
    display: flex;
    flex-direction: column;
}

// Default layout styling
.container {
    flex: none;
    min-height: 90%;
    background-color: $background;

    .banner {
        // position: relative;
        text-align: center;
        color: #ffffff;
        background: $background;
        z-index: 1;
        // background-color: #1a354b;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='384' height='384' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23285172' stroke-width='1.3'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23285172'%3E%3Ccircle cx='769' cy='229' r='6'/%3E%3Ccircle cx='539' cy='269' r='6'/%3E%3Ccircle cx='603' cy='493' r='6'/%3E%3Ccircle cx='731' cy='737' r='6'/%3E%3Ccircle cx='520' cy='660' r='6'/%3E%3Ccircle cx='309' cy='538' r='6'/%3E%3Ccircle cx='295' cy='764' r='6'/%3E%3Ccircle cx='40' cy='599' r='6'/%3E%3Ccircle cx='102' cy='382' r='6'/%3E%3Ccircle cx='127' cy='80' r='6'/%3E%3Ccircle cx='370' cy='105' r='6'/%3E%3Ccircle cx='578' cy='42' r='6'/%3E%3Ccircle cx='237' cy='261' r='6'/%3E%3Ccircle cx='390' cy='382' r='6'/%3E%3C/g%3E%3C/svg%3E");

        .nav {
            padding-top: 1rem;

            .nav-brand {
                margin-top: 1em;
                margin-bottom: 1em;
                display: flex;
                align-items: center;
                justify-content: center;
                a {
                    text-decoration: none;
                    color: #ffffff;
                    font-weight: bold;
                    background-color: $secondary-color;
                    padding: 0.5rem 1rem;
                    border-radius: 2px;

                    &:focus {
                        border: none;
                    }
                }
            }

            ul.nav-list {
                padding: 0 !important;
                list-style-type: none;
                display: flex;
                flex-direction: row;
                justify-content: center;
                flex-wrap: wrap;

                li.nav-item {
                    padding: 0.5rem;

                    a {
                        text-decoration: none;
                        color: $secondary-color;
                        transition: all 0.4s ease-in-out;

                        &::after {
                            border-bottom: 3px solid $secondary-color;
                            content: '';
                            display: block;
                            font-weight: inherit;
                            left: 0;
                            transition: 0.4s ease-in-out;
                            padding-bottom: 8px;
                            width: 0;
                        }
                    }

                    &.active {
                        a {
                            &::after {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .banner-content {
            padding-bottom: 2rem;
            width: 92%;
            margin: auto;

            h1 {
                font-size: 3rem;
                font-weight: 500;
                color: $secondary-color;
            }

            h4 {
                font-size: 1.25rem;
                font-weight: 300;
                
                &.banner-content-subheading {
                    opacity: 0.8;
                }
            }
        }
    }

    .main {
        word-wrap: none;
        text-align: left;
        letter-spacing: 0px;
    }
}


// Page Footer
.footer {
    background-color: $background;
    .footer-text {
        font-size: 0.9rem;
        opacity: 0.9;
        width: 90%;
        margin: auto;
        padding: 1.5rem 0;
        text-align: center;
        a {
            text-decoration: none;
            color: $secondary-color;
            padding: 2px 5px;

            &:hover {
                background: $gradient;
                color: #ffffff;
                border-radius: 5px;
            }
        }
    }
}

// Responsive styles and media queries
@import 'mobile';