.home-banner {
    .home-banner-content {
        margin-top: 3rem;
        text-align: left;
        padding: 2rem 1rem 4rem 1rem;

        h1 {
            font-size: 2rem;
            font-weight: 900;
            line-height: 1;
            color: $secondary-color;
        }

        .title {
            padding-bottom: 2rem;
        }

        .call-wrapper {
            margin-top: 2rem;
            justify-content: start;
        }

        .banner-link {
            margin-top: 0.5rem;
            margin-right: .5rem;
            padding: 0.7rem 1rem;
            border-radius: 2px;
            font-size: 1.2rem;
            font-weight: 400;
            color: #ffffff;
            text-decoration: none;
            border: 1px solid $secondary-color;
            color: $secondary-color;

            &.banner-link-secondary {
                background: $secondary-color;
                color: #ffffff;
            }

            &:hover {
                background-color: $secondary-color;
                transition: all 0.4s ease-in-out;
                color: #ffffff;
            }
        }
    }
}

.section-title {
    font-size: 25px;
    line-height: 20px;
    color: $secondary-color;
}

.home {

    .intro {}

    .services-wrapper {
        padding: 1rem;

        // margin: auto;

        .services {

            .service {
                // width: 100%;
                padding: 1rem;
                border-radius: 5px;
                transition: 0.3s;
                margin-bottom: 2rem;
                text-align: left;
                box-shadow: 2px 8px 16px 0 rgba(0, 0, 0, 0.2);
                background: $light-background;

                &:hover {
                    box-shadow: 2px 8px 16px 0 rgba(0, 0, 0, 0.2);
                    background: $light-background;
                }

                .service-icon {
                    svg {
                        height: 4rem;
                        width: 4rem;
                        fill: $secondary-color;
                    }

                    text-align: center;
                }

                .service-heading {
                    font-family: Georgia, Times, 'Times New Roman', serif;
                    font-size: 1.25rem;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    font-weight: 700;
                    text-align: center;
                }

                .service-content {
                    color: #b9d1f2;
                    line-height: 21px;
                    font-weight: 400;
                    font-size: 1rem;
                }
            }
        }
    }

    .status-wrapper {
        padding: 1rem;

        .statuses {

            .status {
                margin-right: 0.5rem;
                padding: 0.5rem;
                border-radius: 5px;
                transition: 0.3s;
                margin-bottom: 2rem;
                text-align: center;
                // box-shadow: 2px 8px 16px 0 rgba(0, 0, 0, 0.2);
                // background: $light-background;

                display: flex;
                flex-direction: column;

                span {
                    svg {
                        height: 4rem;
                        width: 4rem;
                        fill: $secondary-color;
                    }
                }
            }
        }
    }

    .more {
        padding: 1rem;

        p {
            font-size: 1rem;
            line-height: 1.3;
        }

        .social-icon {
            margin-bottom: 1rem;

            a {
                text-decoration: none;
                color: $secondary-color;
                padding: 3px 8px;
    
                &:hover {
                    background: $gradient;
                    color: #ffffff;
                    border-radius: 5px;
                }
            }
        }
    }

    .intro {
        h3 {
            font-size: 25px !important;
            line-height: 40px;
            font-weight: 400 !important;
        }
    }

    ul {
        list-style: circle;
    }

    a {
        color: $secondary-color;
        text-decoration: none;
        // padding: 2px 2px;

        &:hover {
            color: $primary-color;
            text-decoration: underline;
        }
    }
}

// .banner { Desktop version
//     margin: 2rem;
//     background: #172430;
//     color: #ffffff;

//     .nav{
//         display: flex;
//         flex-direction: row;
//         justify-content: space-between;

//         ul{
//             list-style: none;
//             display: flex;
//             flex-direction: row;
//             justify-items: flex-end;

//             li{
//                 a{
//                     text-decoration: none;
//                     color: #ffffff;
//                     padding: 0rem 1rem;
//                 }
//             }
//         }
//     }
// }
// background-color: rgb(245, 84, 111);