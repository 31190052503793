$secondary-color: #DB9A64;
$primary-color:rgba(242, 112, 156, 1);
$gradient: linear-gradient(90deg, $primary-color 0%, $secondary-color 100%);
$background: #1a354b;
$light-background: #285172;
$old: rgb(235, 67, 37);



@mixin easeOut {
    transition: all 0.1s ease-in-out;
}


// Media Query Mixins
// The layout is mobile first so only medium plus media queries are added..
// @mixin mediaSm {

//     // Small screen media query
//     @media screen and (max-width: 570px) {
//         @content;
//     }
// }

@mixin mediaMd {

    // Medium Small screen media query
    @media screen and (min-width: 570px) {
        @content;
    }
}

@mixin mediaMdLg {

    // Medium large screen media query
    @media screen and (min-width: 768px) {
        @content;
    }
}

@mixin mediaLg {

    // Normal Laptop screen media query
    @media screen and (min-width: 968px) {
        @content;
    }
}

@mixin mediaXl {

    // Extra Large screen media query
    @media screen and (min-width: 1024px) {
        @content;
    }
}

@mixin mediaLargeMax {
    @media screen and (max-width: 1920px) {
        @content;
    }
}