// Large phones and small tablets
@include mediaMd {

    .container {
        .banner {

            .nav {
                padding: 1rem 5rem 0 5rem;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .nav-brand {
                    margin-top: 1em;
                    margin-bottom: 1em;
                    display: flex;
                    align-items: center;

                    a {
                        text-decoration: none;
                        color: #ffffff;

                        &:focus {
                            border: none;
                        }
                    }
                }

                ul.nav-list {
                    li.nav-item {
                        padding: 0.5rem 1rem 0.5rem 1rem;
                    }
                }
            }

            &.home-banner {
                .home-banner-content {
                    padding: 2rem 1rem 4rem 5rem;

                    .intro-greetings,
                    .title {
                        font-size: 1.5rem;
                    }

                    h1 {
                        font-size: 3rem;
                    }

                    .banner-link {
                        margin-right: 1.5rem;
                    }
                }
            }
        }

        .main {

            .home {

                .section-title {
                    font-size: 1.85rem;
                }

                .services-wrapper,
                .status-wrapper,
                .more {
                    padding-left: 5rem;
                    padding-right: 5rem;

                    .services {
                        .service {

                            .service-heading,
                            .service-icon {
                                text-align: center;
                            }
                        }
                    }

                    .statuses {
                        width: 70%;
                        margin: auto;
                        // .status {
                        //     flex-direction: column;
                        // }
                    }
                }
            }

            .projects {
            
                .project-list {
                    .project-item {
                        padding: 1rem 3rem;
                        .img-container {
                            padding: 1.5rem;
                            .project-img {
                                margin-bottom: -50px;
                            }
                        }
            
                        .details {
                            .project-title {
                                margin: 3rem 0rem 0.45rem 0rem;
                            }
                        }
                    }
            
                }
            }
        }
    }
}


// Large tablets and small laptops
@include mediaMdLg {
    .container {
        .banner {
            .nav {
                padding: 1rem 7rem 0 7rem;
                .nav-list {
                    .nav-item {
                        a{
                            &:hover {
                                &::after {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            &.home-banner {
                .home-banner-content {
                    padding: 2rem 1rem 4rem 8rem;
                }
            }

            .banner-content {

                padding-top: 80px;

                h1 {
                    font-size: 3.5rem;
                }
                h4 {
                    font-size: 1.75rem;
                    font-weight: 300;
                }
            }
        }

        .main {
            .home {

                .services-wrapper,
                .status-wrapper,
                .more {
                    padding-left: 8rem;
                    padding-right: 8rem;
                }
            }
        }
    }
}

// Normal laptops and larger
@include mediaLg {
    .container {

        .banner {
            .banner-content {
                padding-top: 100px;
            }
        }
        
        .main {

            .home {

                .services-wrapper {

                    .services {
                        .service {
                            padding: 1rem 2.5rem;
                        }
                    }
                }

                .status-wrapper {

                    .statuses {
                        width: 100%;
                        margin: none;
                        display: flex;
                        justify-content: space-between;

                        .status {
                            flex: 1 1 0px;
                        }
                    }
                }
            }

            .projects {
            
                .project-list {
            
                    .project-item {
                        display: flex;
        
                        .img-container {
                            width: 51%;
                            padding: 2rem;
                            margin-bottom: 30px;
                            margin-right: 1rem;

                            .project-img {
                                margin-bottom: -80px;
                            }
                        }
            
                        .details {
                            width: 49%;

                            padding: 0 1rem;
                            .project-title {
                                font-size: 2.62rem;
                                margin: 0rem 0rem 0.45rem 0rem;
                                line-height: 110%;
                            }
                            .description{
                                line-height: 160%;
                                font-size: 1.2rem;
                                color: #b9d1f2;
                            }

                            .tech {
                                .tech-tag {
                                    font-size: 0.85rem;
                                    padding: 0.25rem;
                                }
                            }

                            .project-controls{
                                .preview-link,
                                .github-link {
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
            
                }
            }
        }
    }
}

// Large screens
@include mediaXl {
    .container {
        .main {

            .home {

                .services-wrapper {

                    .services {
                        display: flex;
                        // flex-wrap: wrap;

                        .service {
                            flex: 1 1 0px;
                            margin-right: 1rem;
                            padding: 1rem;
                        }
                    }
                }
            }

            .projects {
            
                .project-list {
            
                    .project-item {
                        align-items: center;

                        .img-container {
                            padding: 3rem;
                            width: 60%;
                            
                            .project-img {
                                margin-bottom: -100px;
                            }
                        }
            
                        .details {
                            width: 40%;
                            .project-title {
                                font-size: 3rem;
                                margin: 0rem 0rem 0.45rem 0rem;
                            }
                            .description{
                                font-size: 1.2rem;
                                color: #b9d1f2;
                            }

                            .tech {
                                .tech-tag {
                                    font-size: 0.85rem;
                                    padding: 0.25rem;
                                }
                            }

                            .project-controls{
                                margin-top: 1rem;
                                .preview-link,
                                .github-link {
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
            
                }
            }
        }
    }
}

@include mediaLargeMax {
    .banner-content {
        max-width: 925px;
    }

    .projects {
        .project-list {
            max-width: 1300px;
            margin: auto;
        }
    }
}

@media screen and (min-width: 1921px) {
    .container {
        .banner {
            .nav {
                padding: 1rem 0;
                max-width: 1700px;
                margin: auto;
                .nav-brand {
                    font-size: 1.5rem;
                }
                .nav-list {
                    .nav-item {
                        font-size: 2rem;
                    }
                }
            }
            .banner-content {
                max-width: 925px;
                padding-top: 150px;
        
                h1 {
                    font-size: 5.125rem;
                }
        
                .banner-content-subheading {
                    font-size: 2.875rem;
                }
            }

            &.home-banner {
                .home-banner-content {
                    padding: 0;
                    padding-top: 150px;
                    max-width: 1700px;
                    margin: auto;
                    
                    .intro-greetings,
                    .title {
                        font-size: 2.875rem;
                    }

                    h1 {
                        font-size: 5.125rem;
                    }

                    .banner-link {
                        margin-right: 2rem;
                        padding: 1rem 1.5rem;
                        font-size: 1.75rem;
                    }
                }
            }
        }
        
        .main {
            .projects {
                .project-list {
                    padding-top: 250px;
                    max-width: 1700px;
                    margin: auto;
                    
                    .project-item {
                        margin-bottom: 100px;

                        .details {
                            line-height: 2;
                            .project-title {
                                font-size: 3.85rem;
                                line-height: 2;
                            }
                            .description {
                                font-size: 1.85rem;
                                line-height: 2;
                            }
                            .tech {
                                .tech-tag {
                                    font-size: 1.25rem;
                                    line-height: 1.5;
                                    padding-right: .5rem;
                                    padding-left: .5rem;
                                }
                            }

                            .project-controls {
                                .preview-link, .github-link {
                                    font-size: 1.75rem;
                                }
                            }
                        }
                    }
                }
            }
    
            .home {
                padding-top: 150px;
                max-width: 1700px;
                margin: auto;
    
                .section-title{
                    font-size: 3rem;
                }

                .services-wrapper{
                    padding: 0;
                    .services {
                        .service {
                            margin-right: 2rem;
                            padding: 1.2rem;
                            .service-heading {
                                font-size: 2rem;
                            }

                            .service-content{
                                font-size: 1.75rem;
                                line-height: 1.1;

                                &p{
                                    font-size: 2rem;
                                }
                            }
                        }
                    }
                }

                .status-wrapper {
                    padding: 0;
                    margin-top: 100px;
                    .statuses {
                        .status {
                            font-size: 1.75rem;
                            span{
                                svg{
                                    height: 6rem;
                                    width: 6rem;
                                }
                            }
                        }
                    }
                }

                .more {
                    padding: 0;
                    margin-top: 100px;
                    p{
                        font-size: 1.75rem;
                    }
                }
            }
        }
    }

    .footer {
        .footer-text {
            font-size: 1.75rem;
        }
    }
}

// .projects {
//     .title {
//         font-size: 2rem;
//         margin-bottom: 0.55rem;
//     }

//     .project-list {
//         margin: 1.5rem auto auto auto;

//         .project-item {
//             display: block;

//             .project-img,
//             .details {
//                 display: block;
//                 grid-area: img;
//                 width: 100%;
//             }

//             .details {
//                 .project-title {
//                     margin-top: 1rem;
//                 }
//             }
//         }
//     }
// }

// .about {
//     display: block;

//     .bio,
//     .education,
//     .work-history {
//         margin-bottom: 1rem;
//         display: block;
//     }
// }

// .contact {
//     .contact-form {
//         form {
//             width: 100%;
//         }
//     }
// }